import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import FeatureCard2 from '../components/feature-card2'
import TestimonialCard1 from '../components/testimonial-card1'
import './onepage.css'

const Onepage = (props) => {
  return (
    <div lang="en" className="onepage-container">
      <Helmet>
        <title>Skaidi</title>
        <meta property="og:title" content="Skaidi" />
      </Helmet>
      <a href="#skaidi" className="onepage-link">
        <div className="onepage-container01">
          <svg viewBox="0 0 1024 1024" className="onepage-icon">
            <path d="M214 598l298-300 298 300h-170v256h-256v-256h-170zM214 170h596v86h-596v-86z"></path>
          </svg>
        </div>
      </a>
      <img
        id="skaidi"
        alt="logo"
        src="/external/skaidi_nobg.svg"
        className="onepage-logo"
      />
      <div className="onepage-container02">
        <img
          alt="image"
          src="/external/skaidi_name.svg"
          loading="eager"
          className="onepage-image"
        />
        <h1 className="onepage-heading">
          <span>SAP Technology expertise at your service</span>
          <br></br>
        </h1>
        <span className="onepage-text02">
          <span className="onepage-text03">
            We excel at seamlessly integrating SAP systems, automating
            processes, and developing cutting-edge platforms. Partnering with us
            means streamlined operations, improved efficiency, and faster
            growth. With our unwavering dedication to your success, we provide
            unparalleled service and support throughout the entire process. Our
            extensive experience and knowledge of creating innovative platforms,
            automating processes, and seamless integrations make us the ideal
            choice to help you achieve your goals. Visit our website to learn
            more, and let&apos;s work together to elevate your SAP experience.
          </span>
          <br className="onepage-text04"></br>
        </span>
        <a
          href="mailto:info@skaidi.com?subject="
          className="onepage-link1 button"
        >
          Contact Us
        </a>
        <span className="onepage-text05">
          Or send us an email at info@skaidi.com
        </span>
      </div>
      <div className="onepage-features">
        <h1 id="sap-consulting-services" className="onepage-text06">
          Tailored SAP consulting services
        </h1>
        <h3 className="onepage-text07">
          <span className="onepage-text08">
            Skaidi offers an array of services tailored to meet your unique SAP
            needs. Our team specializes in integration, automation, and
            platforms, enabling us to provide complete solutions that simplify
            operations, increase productivity, and drive business growth.
          </span>
          <br className="onepage-text09"></br>
          <br></br>
        </h3>
        <div className="onepage-container03">
          <FeatureCard2
            title="SAP Integration Solutions"
            description="Our integration solutions offer a seamless connection between your SAP systems and other applications, databases, and third-party platforms. This facilitates the smooth flow of data and real-time information exchange, resulting in a unified and interconnected business ecosystem."
            rootClassName="rootClassName4"
          ></FeatureCard2>
          <FeatureCard2
            title="SAP and S/4HANA Platform Solutions"
            description="Our platform solutions empower you with the latest tools and technologies to drive innovation and stay ahead in the digital world. We help you seamlessly integrate SAP Cloud Platform, SAP HANA, and other cutting-edge platforms to unlock new opportunities, enhance collaboration, and leverage advanced capabilities."
            rootClassName="rootClassName5"
          ></FeatureCard2>
          <FeatureCard2
            title="SAP/IT Consulting and Advisory"
            description="Our team of experienced consultants and advisors is here to guide you along your SAP journey. We provide personalized recommendations, strategic insights, and best practices to help you make informed decisions and optimize your SAP environment. Our ultimate goal is to align your IT strategies with your business objectives for maximum success."
            rootClassName="rootClassName6"
          ></FeatureCard2>
          <FeatureCard2
            title="Implementation and Support"
            description="We provide comprehensive SAP deployment services, from initial implementation to ongoing support. Our team is dedicated to working closely with you to ensure a successful deployment. We handle system configuration and customization, training, and post-implementation support. Our goal is to make the transition smooth and seamless, without hassle."
            rootClassName="rootClassName7"
          ></FeatureCard2>
        </div>
      </div>
      <div className="onepage-container04">
        <h1 id="sap-consulting-experts" className="onepage-text11">
          <span>Our team of SAP experts</span>
          <br></br>
        </h1>
        <span className="onepage-text14">
          <span className="onepage-text15">
            Our team boasts a diverse range of skilled individuals whose
            collective expertise ensures our success. Our integration
            specialists, automation experts, and platform architects collaborate
            seamlessly to provide all-encompassing solutions that lead to
            enhanced efficiency and optimal results.
          </span>
          <br></br>
        </span>
        <div className="onepage-container05">
          <TestimonialCard1
            name="Samuli"
            role="System Architect, CEO"
            email="samuli.kaisanlahti@skaidi.com"
            quote="Samuli is an experienced system architect specializing in business continuity and process orchestration in SAP systems. With expertise in SAP lifecycle scenarios, from planning to execution and governance, he has a proven track record as a dynamic leader and adept project manager, service manager, and business developer in the IT industry."
            pictureSrc="/external/samuli%20face-200h.jpg"
            linkedinUrl="https://www.linkedin.com/in/samuli-kaisanlahti-5ba9259/"
            rootClassName="rootClassName10"
          ></TestimonialCard1>
          <TestimonialCard1
            name="Tomi"
            role="SAP Platform Architect"
            email="tomi.ollila@skaidi.com"
            quote="As an expert in SAP Platform and IT architecture, he possess exceptional skills in configuring cloud platforms and automating business IT systems deployment. His vast knowledge and extensive experience in various SAP-related IT scenarios allows him to seamlessly integrate systems and ensure optimal performance."
            pictureSrc="/external/tomi%20face-200h.jpg"
            linkedinUrl="https://www.linkedin.com/in/tomi-ollila-3144801b/"
            rootClassName="rootClassName11"
          ></TestimonialCard1>
          <TestimonialCard1
            name="Thomas"
            role="IT Automation expert"
            email="thomas.pfister@skaidi.com"
            quote="Thomas is a highly skilled IT professional specializing in automation and configuration management. With expertise in scripting and leveraging diverse automation technologies, he excels at resolving complex challenges. His extensive knowledge includes SAP Basis, Unix shell scripting, Windows PowerShell scripting, and Python programming. "
            pictureSrc="/external/thomas%20face-200w.jpg"
            linkedinUrl="https://www.linkedin.com/in/thpfister/"
            rootClassName="rootClassName20"
          ></TestimonialCard1>
          <TestimonialCard1
            name="Kalle"
            role="SAP Integration Architect"
            email="kalle.anttila@skaidi.com"
            quote="Kalle, our Senior Integration Architect, excels in SAP logistics consulting with a strong background in international SAP ECC and S/4 implementation projects. He is skilled in project management, team leadership, and integration architecture, actively contributing from design to implementation and holding a certification in SAP Integration Suite."
            pictureSrc="/external/kalle%20face-200w.jpg"
            linkedinUrl="https://www.linkedin.com/in/kalle-anttila-2579121/"
            rootClassName="rootClassName13"
          ></TestimonialCard1>
        </div>
        <div className="onepage-container06">
          <TestimonialCard1
            name="Bengt"
            role="SAP Integration architect"
            email="bengt.wentus@skaidi.com"
            quote="With years of experience in manufacturing industry, Bengt remains passionate to model processes, integrations and architecture of how things are to be built. He has a large influence on how forest industry companies integrate systems internally and externally. He is continues as advisory to papinet standard, contributing to the whole industry to excel."
            pictureSrc="/external/bengt-200h.jpg"
            linkedinUrl="https://www.linkedin.com/in/bengt-wentus-1618a01/"
            rootClassName="rootClassName30"
          ></TestimonialCard1>
          <TestimonialCard1
            name="Klaus"
            role="SAP Platform consultant"
            email="klaus.kivela@skaidi.com"
            quote="Klaus develops and supports SAP platforms and automation solutions. He also specializes in SAP platform security. Having experience from IT management roles on SAP Customer organizations, he can also take a lead roles in SAP Customer organizations."
            pictureSrc="/external/klaus_bw-200w.jpg"
            linkedinUrl="https://www.linkedin.com/in/klaus-kivel%C3%A4-24b8783/"
            rootClassName="rootClassName31"
          ></TestimonialCard1>
          <TestimonialCard1
            name="Tiina"
            role="Program Manager"
            email="tiina.laurila@skaidi.com"
            quote="Tiina is an extremely experienced SAP program manager. She has an outstanding track record handling large SAP projects from design to implementation to application support. She masters IT supplier relationships and can masters both application management and project management from IT supplier and Customer perspectives."
            pictureSrc="/external/tiina_bw-200w.jpg"
            linkedinUrl="https://www.linkedin.com/in/tiina-laurila-14791813/"
            rootClassName="rootClassName32"
          ></TestimonialCard1>
        </div>
      </div>
      <div className="onepage-container07">
        <a
          href="mailto:info@skaidi.com?subject="
          className="onepage-link2 button"
        >
          <span className="onepage-text17">
            <span className="onepage-text18">Contact Us</span>
            <br></br>
          </span>
        </a>
        <Link to="/join-our-team" className="onepage-navlink button">
          <span className="onepage-text20">
            <span className="onepage-text21">Work with us</span>
            <br></br>
          </span>
        </Link>
        <a
          href="https://www.linkedin.com/company/skaidiconsulting"
          target="_blank"
          rel="noreferrer noopener"
          className="onepage-link3"
        >
          <svg viewBox="0 0 877.7142857142857 1024" className="onepage-icon2">
            <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
          </svg>
        </a>
      </div>
      <footer className="onepage-footer">
        <img
          alt="logo"
          src="/external/skaidi_nobg.svg"
          className="onepage-image1"
        />
        <div className="onepage-container08">
          <span className="onepage-text23">
            © 2024 Skaidi Consulting Oy, All Rights Reserved.
          </span>
        </div>
      </footer>
      <header data-thq="thq-navbar" className="onepage-navbar-interactive">
        <div
          data-thq="thq-navbar-nav"
          data-role="Nav"
          className="onepage-desktop-menu"
        >
          <nav
            data-thq="thq-navbar-nav-links"
            data-role="Nav"
            className="onepage-nav"
          >
            <a href="#skaidi" className="onepage-link4">
              Home
            </a>
            <a href="#sap-consulting-services" className="onepage-link5">
              What we do
            </a>
            <a href="#sap-consulting-experts" className="onepage-link6">
              Who we are
            </a>
            <Link to="/join-our-team" className="onepage-navlink1">
              Work with us
            </Link>
          </nav>
        </div>
        <div data-thq="thq-burger-menu" className="onepage-burger-menu">
          <svg viewBox="0 0 1024 1024" className="onepage-icon4">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="onepage-mobile-menu">
          <div
            data-thq="thq-mobile-menu-nav"
            data-role="Nav"
            className="onepage-nav1"
          >
            <div className="onepage-container09">
              <img
                alt="image"
                src="/external/skaidi_nobg.svg"
                className="onepage-image2"
              />
              <div data-thq="thq-close-menu" className="onepage-menu-close">
                <svg viewBox="0 0 1024 1024" className="onepage-icon6">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav
              data-thq="thq-mobile-menu-nav-links"
              data-role="Nav"
              className="onepage-nav2"
            >
              <a href="#skaidi" className="onepage-home">
                Home
              </a>
              <a href="#sap-consulting-experts" className="onepage-who-we-are">
                Who we are
              </a>
              <a href="#sap-consulting-services" className="onepage-what-we-do">
                <span>
                  What
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  we
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>do</span>
              </a>
            </nav>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Onepage
