// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feature-card4-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.feature-card4-icon {
  fill: #BDD061;
  width: 2rem;
  height: 2rem;
  margin-bottom: var(--dl-space-space-unit);
}
.feature-card4-text {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.feature-card4-text1 {
  color: var(--dl-color-gray-900);
}
.feature-card4-root-class-name {
  width: 100%;
}




@media(max-width: 479px) {
  .feature-card4-text {
    font-style: normal;
    font-weight: 600;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/feature-card4.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mCAAmC;EACnC,uCAAuC;EACvC,uBAAuB;EACvB,sBAAsB;EACtB,2BAA2B;AAC7B;AACA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,yCAAyC;AAC3C;AACA;EACE,gBAAgB;EAChB,yCAAyC;AAC3C;AACA;EACE,+BAA+B;AACjC;AACA;EACE,WAAW;AACb;;;;;AAKA;EACE;IACE,kBAAkB;IAClB,gBAAgB;EAClB;AACF","sourcesContent":[".feature-card4-feature-card {\n  width: 100%;\n  display: flex;\n  padding: var(--dl-space-space-unit);\n  max-width: var(--dl-size-size-maxwidth);\n  align-items: flex-start;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n.feature-card4-icon {\n  fill: #BDD061;\n  width: 2rem;\n  height: 2rem;\n  margin-bottom: var(--dl-space-space-unit);\n}\n.feature-card4-text {\n  font-weight: 600;\n  margin-bottom: var(--dl-space-space-unit);\n}\n.feature-card4-text1 {\n  color: var(--dl-color-gray-900);\n}\n.feature-card4-root-class-name {\n  width: 100%;\n}\n\n\n\n\n@media(max-width: 479px) {\n  .feature-card4-text {\n    font-style: normal;\n    font-weight: 600;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
